import { Injectable } from "@angular/core";
import { MessageService, PrimeNGConfig } from "primeng/api";
import { MESSAGE_SEVERITY } from "../constants/app.consts";

@Injectable({
  providedIn: "root",
})
export class AppToastService {
  constructor(
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig
  ) { }

  showToasterMessage(severity: string, summary: string, msg: string) {
    this.clearAllToasterMessages();
    this.messageService.add({
      severity: severity,
      summary: summary.toUpperCase(),
      detail: msg,
    });
    setTimeout(() => {
      this.clearAllToasterMessages();
    }, 5000);
  }

  showSuccess(msg: string) {
    this.messageService.add({
      severity: MESSAGE_SEVERITY.SUCCESS,
      summary: MESSAGE_SEVERITY.SUCCESS.toUpperCase(),
      detail: msg,

    });
    setTimeout(() => {
      this.clearAllToasterMessages();
    }, 5000);
  }

  showWarn(msg: string) {
    this.messageService.add({
      severity: MESSAGE_SEVERITY.WARN,
      summary: MESSAGE_SEVERITY.WARN.toUpperCase(),
      detail: msg,
    });
    setTimeout(() => {
      this.clearAllToasterMessages();
    }, 5000);
  }

  showError(msg: string) {
    this.messageService.add({
      severity: MESSAGE_SEVERITY.ERROR,
      summary: MESSAGE_SEVERITY.ERROR.toUpperCase(),
      detail: msg,
    });
    setTimeout(() => {
      this.clearAllToasterMessages();
    }, 5000);
  }

  showInfo(msg: string) {
    this.messageService.add({
      severity: MESSAGE_SEVERITY.INFO,
      summary: MESSAGE_SEVERITY.INFO.toUpperCase(),
      detail: msg,
    });
    setTimeout(() => {
      this.clearAllToasterMessages();
    }, 5000);
  }

  clearAllToasterMessages() {
    this.messageService.clear();
  }
}
