<!-- <nav class="layout-breadcrumb">
    <ol>
        <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
            <li>{{ item.label }}</li>
            <li *ngIf="!last" class="layout-breadcrumb-chevron">/</li>
        </ng-template>
    </ol>
</nav> -->

<div class="col-12 pl-0 pr-0">
    
        <p-breadcrumb [model]="breadcrumbItems" [home]="{ icon: 'pi pi-home' ,routerLink: ['/home']}"></p-breadcrumb>
    
</div>
