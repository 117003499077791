import { Injectable } from "@angular/core";
import { ConfirmEventType, ConfirmationService, MessageService } from "primeng/api";

@Injectable()
export class ConfirmationDialogService {

    constructor(private confirmationService: ConfirmationService,
        private messageService: MessageService) { }

    //confirmation dialog
    confirmDialog(confirmDialogData: any, bindMethod: any, componentThis:any) {
        this.confirmationService.confirm({
            message: confirmDialogData.message,
            header: confirmDialogData.header,
            icon: confirmDialogData.icon,
            accept: () => {
                let acceptDataObj: any = { eventType: 'ACCEPT', data: confirmDialogData.data, componentThis: componentThis };
                bindMethod(acceptDataObj);
                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
            },
            reject: (type: any) => {
                let rejectDataObj: any = { data: confirmDialogData.data, componentThis: componentThis }
                switch (type) {
                    case ConfirmEventType.REJECT:
                        rejectDataObj.eventType = 'REJECT';
                        bindMethod(rejectDataObj);
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        rejectDataObj.eventType = 'CANCEL';
                        bindMethod(rejectDataObj);
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                    default:
                        break;
                }
            }
        });
    }

}