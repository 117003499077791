export const environment = {
    production: false,
    appInsightsInstrumentationKey: '',
    azureClientId: '',
    azureTenantId: '',
    secretId: '',
    apiVersion: '1',
    host: '',
    userManagementServiceHost: 'https://user-management-service-fitzone-dev-app.azurewebsites.net/',
    venueLeadServiceHost: 'https://venueleads-service-fitzone-dev-app.azurewebsites.net/',
    venueStaffServiceHost: 'https://venue-staff-management-service-fitzone-dev-app.azurewebsites.net/',
    attendanceManagementServiceHOst: 'https://attendance-management-service-fitzone-dev-app.azurewebsites.net/',
    roasteringManagementServiceHOst: 'https://rostering-service-fitzone-dev-app.azurewebsites.net/',
    venueStaffManagementServiceHOst: 'https://venue-staff-management-service-fitzone-dev-app.azurewebsites.net/',
    gxPlannerManagementServiceHOst: 'https://gx-class-management-service-fitzone-dev-app.azurewebsites.net/',
    venueManagementServiceHOst: 'https://venue-management-service-fitzone-dev-app.azurewebsites.net/',
    customerProfileServiceHost: 'https://customer-management-service-fitzone-dev-app.azurewebsites.net/',
    fitzonePersonalTrainingApiDev:'https://personal-training-service-fitzone-dev-app.azurewebsites.net/',
    venuestafffitzonedev:'https://venue-staff-management-service-fitzone-dev-app.azurewebsites.net/',
    filedetailsfitzonedev:'https://file-management-service-fitzone-dev-app.azurewebsites.net/',
    customerMgmtServiceHost: 'https://customer-management-service-fitzone-dev-app.azurewebsites.net/',
    orderMgmtServiceHost:'https://order-management-service-fitzone-dev-app.azurewebsites.net/',
    helpDeskServiceHost: 'https://helpdesk-service-fitzone-dev-app.azurewebsites.net/',
    targetManagementService:"https://target-management-service-fitzone-dev-app.azurewebsites.net/",
    subscriptionMgmtServiceHost: 'https://subscription-management-service-fitzone-dev-app.azurewebsites.net/',
    subscriptionRecordServiceHost: 'https://subscription-management-service-fitzone-dev-app.azurewebsites.net/',
    sessionServiceHost: 'https://personal-training-service-fitzone-dev-app.azurewebsites.net/',
};
