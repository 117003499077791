import { Injectable } from "@angular/core";
import { MasterService } from "../core/services/master.service";
import { API_END_POINTS } from "../core/constants/app.apiEndPoints";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private masterService: MasterService) {}

  generateToken(email: string) {
    const url = this.masterService.constructApiUrl(API_END_POINTS.GenerateOtp);
    return this.masterService.post(
      url,
      { email: email, otpGenAttempt: 1 },
      undefined,
      {
        loader: false,
      }
    );
  }

  validateOtp(data: any) {
    const url = this.masterService.constructApiUrl(API_END_POINTS.ValidateOtp);
    return this.masterService.get(
      url,
      {
        params: {
          userid: data.userid,
          otp: data.otp,
        },
      },
      {
        loader: false,
      }
    );
  }

  getAllRoles() {
    const url = this.masterService.constructApiUrl(API_END_POINTS.GetAllRoles);
    return this.masterService.get(url, undefined, { loader: false });
  }

  fetchAllVenuesByUser(userId: string) {
    const url = this.masterService.constructApiUrl(
      API_END_POINTS.GetAllVenuesByUser
    );
    return this.masterService.get(
      url,
      {
        params: {
          UserId: userId,
        },
      },
      { loader: false }
    );
  }

  fetchAllVenues(userId: string) {
    const url = this.masterService.constructApiUrl(API_END_POINTS.GetAllVenues);
    return this.masterService.get(
      url,
      {
        params: {
          userid: userId,
        },
      },
      { loader: false }
    );
  }
}
