import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login.component";
import { AppConfigModule } from "src/app/layout/config/app.config.module";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  CountdownConfig,
  CountdownGlobalConfig,
  CountdownModule,
} from "ngx-countdown";
import { ChipModule } from "primeng/chip";

function countdownConfigFactory(): CountdownConfig {
  return { format: `mm:ss` };
}

const routes: Routes = [{ path: "", component: LoginComponent }];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    AppConfigModule,
    PasswordModule,
    InputTextModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CountdownModule,
    ChipModule,
    RouterModule.forChild(routes),
  ],
  providers: [
    { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class LoginModule {}
