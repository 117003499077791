import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private venueBehaviorSubject = new BehaviorSubject<any>(null);
  private TrainerInfoBehaviorSubject = new BehaviorSubject<any>(null);
  venueListBehaviorSubject = new BehaviorSubject<any>(null);
  private dataSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSubject.asObservable();
  constructor() {}

  setVenueBehaviorSubject(value: any) {
    this.venueBehaviorSubject.next(value);
  }

  getVenueBehaviorSubject() {
    return this.venueBehaviorSubject.asObservable();
  }

  updateData(data: any) {
    this.dataSubject.next(data);
    // Save data to localStorage
    localStorage.setItem('sharedData', JSON.stringify(data));
  }

}
