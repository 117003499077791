import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LoginModule } from './modules/login/login.module';
import { AuthGuard } from './modules/core/services/auth.guard';
const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => LoginModule) },
    {
        path: 'home',
        component: AppLayoutComponent,
        children: [
            { path: '', data: { breadcrumb: 'Home' }, loadChildren: () => import('./demo/components/home/home.module').then((m) => m.HomeModule) },
            { path: 'venue-leads', data: { breadcrumb: 'Venue Leads' }, loadChildren: () => import('./modules/venue-leads/venue-leads.module').then((m) => m.VenueLeadsModule) },
            { path: 'target-management', data: { breadcrumb: 'Target-Management' }, loadChildren: () => import('./modules/target/target.module').then((m) => m.TargetModule) },
            { path: 'checkins', data: { breadcrumb: 'Checkins' }, loadChildren: () => import('./modules/checkins/checkins.module').then((m) => m.CheckinsModule) },
            { path: 'staff', data: { breadcrumb: 'Staff' }, loadChildren: () => import('./modules/staff/staff.module').then((m) => m.StaffModule) },
            { path: 'staff-attendance', data: { breadcrumb: 'Staff Attendance' }, loadChildren: () => import('./modules/staff-attendance/staff-attendance.module').then((m) => m.StaffAttendanceModule) },
            { path: 'shifts-management', data: { breadcrumb: 'Shifts-Management' }, loadChildren: () => import('./modules/shifts-management/shifts-management.module').then((m) => m.ShiftsManagementModule) },
            { path: 'pt-packs', data: { breadcrumb: 'Personal Training Packs' }, loadChildren: () => import('./modules/pt-packs/pt-packs.module').then((m) => m.PtPacksModule) },
            { path: 'pt-trainers', data: { breadcrumb: 'Personal Training Trainers' }, loadChildren: () => import('./modules/pt-trainers/pt-trainers.module').then((m) => m.PtTrainersModule) },
            { path: 'pt-assignpacks', data: { breadcrumb: 'Personal Training Trainers' }, loadChildren: () => import('./modules/pt-assignpacks/pt-assignpacks.module').then((m) => m.PtAssignPacksModule) },
            { path: 'pt-purchase', data: { breadcrumb: 'Personal Training Purchase' }, loadChildren: () => import('./modules/pt-purchase/pt-purchase.module').then((m) => m.PtPurchaseModule) },
            { path: 'gx-planner', data: { breadcrumb: 'GX Planner' }, loadChildren: () => import('./modules/gx-planner/gx-planner.module').then((m) => m.GxPlannerModule) },
            { path: 'gx-schedule', data: { breadcrumb: 'GX Schedule' }, loadChildren: () => import('./modules/gx-schedule/gx-schedule.module').then((m) => m.GxScheduleModule) },
            // { path: 'support', data: { breadcrumb: 'Support' }, loadChildren: () => import('./demo/components/support/support.module').then((m) => m.SupportModule) },
            { path: 'profile', data: { breadcrumb: 'Profile' }, loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule) },
            { path: 'reports', data: { breadcrumb: 'Reports' }, loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule) },
            { path: 'support', data: { breadcrumb: 'Support' }, loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule) },
            { path: 'customer-management', data: { breadcrumb: 'Customer-Management' }, loadChildren: () => import('./modules/customer-management/customer-management.module').then((m) => m.CustomerManagementModule) },

        ],
        canActivate: [AuthGuard]
    },
    { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./demo/components/auth/auth.module').then((m) => m.AuthModule) },
    { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then((m) => m.LandingModule) },
    { path: 'notfound', loadChildren: () => import('./demo/components/notfound/notfound.module').then((m) => m.NotfoundModule) },
    { path: '**', redirectTo: '/notfound' },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
