import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoaderService } from 'src/app/modules/core/services/loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  typeSelected: string;
  isLoading = false;

  constructor(private spinnerService: NgxSpinnerService, private loaderService: LoaderService) {
    this.typeSelected = 'ball-fussion';
  }

  public showSpinner(): void {
    this.spinnerService.show();

    setTimeout(() => {
      this.spinnerService.hide();
    }, 5000); // 5 seconds
  }

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe({
      next: (response: any) => {
        this.isLoading = response;
      },
      error: (err) => { },
    })
  }
}
