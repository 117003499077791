import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableModule } from "primeng/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressBarModule } from "primeng/progressbar";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { SliderModule } from "primeng/slider";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { OnlyNumberDirective } from "./directives/only-number.directive";
import { DateService } from "./services/date.service";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { AccordionModule } from "primeng/accordion";
import { ConfirmationDialogService } from "./services/confirmationDialog.service";

@NgModule({
  declarations: [OnlyNumberDirective, SpinnerComponent],
  imports: [
    CommonModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    RatingModule,
    ButtonModule,
    SliderModule,
    InputTextModule,
    ToggleButtonModule,
    RippleModule,
    MultiSelectModule,
    DropdownModule,
    ProgressBarModule,
    ToastModule,
    NgxSpinnerModule,
    ButtonModule,
    AccordionModule,
  ],
  providers: [DateService,ConfirmationDialogService],
  exports: [
    NgxSpinnerModule,
    OnlyNumberDirective,
    SpinnerComponent,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
