import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class ValidatorService {
  constructor() {}

  noWhitespaceValidator(control: FormControl) {
    return control.value ? null : { required: true };
  }

  onlyAlphabetsNumbersAndSpacesNotAllowed(c: FormControl) {
    let REGEXP = /^(?![0-9\s]*$)[a-zA-Z0-9\s]+$/;
    return REGEXP.test(c.value)
      ? null
      : {
          nonAlphaNumericValidator: true,
        };
  }

  /* acceptPositiveValidator(c: FormControl) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value < 0) {
        return { nonNegativeValidator: true };
      }
      return null;
    };
  } */

  onlyAlphabetsAllowed(c: FormControl) {
    let REGEXP = /^[a-zA-Z]+$/;
    return REGEXP.test(c.value)
      ? null
      : {
          nonAlphaValidator: true,
        };
  }
  nonNegativeValidator(c: FormControl) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value < 0) {
        return { nonNegativeValidator: true };
      }
      return null;
    };
  }


  
  emailValidator(c: FormControl) {
    //let REGEXP = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    let REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    return REGEXP.test(c.value) ? null : { notValidEmail: true };
}

  phoneNumberValidator(c: FormControl) {
    let REGEXP = /^\d{10}$/;
    return REGEXP.test(c.value)
      ? null
      : {
          notValidPhoneNumber: true,
        };
  }
}
