import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToastModule } from "primeng/toast";

@NgModule({
  declarations: [],
  imports: [CommonModule, ToastModule],
  providers: [],
})
export class CoreModule {}
