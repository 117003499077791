import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

@Injectable()
export class DateService {
  constructor() { }

  GetDaysCount(startDate: any, endDate: any) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diff = Math.round(end.getTime() - start.getTime());
    return Math.ceil(diff) / (1000 * 3600 * 24);
  }

  GetDatesInRange(
    startDate: any,
    endDate: any,
    dateFormat: string = "DD-MMM-YYYY"
  ) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const date = new Date(start.getTime());

    const dates = [];
    const days = [];

    while (date <= end) {
      dates.push({
        date: moment(date).format(dateFormat),
        day: moment(date).format("dddd"),
      });
      // days.push(moment(date).format("dddd"));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  GetLastDates(date: any, noOfDaysAgo: number = 7) {
    const endDate = new Date(date);
    const startDate = new Date(
      endDate.getTime() - noOfDaysAgo * 24 * 60 * 60 * 1000
    );
    // return this.GetDatesInRange(startDate, endDate);
    return startDate;
  }

  getTimeOfDate(date: any) {
    const dateTime = new Date(date).getTime();
  }

  getHoursinMillis(hrs: number) {
    return hrs * 60 * 60 * 1000;
  }

  getMinutesinMillis(mins: number) {
    return mins * 60 * 1000;
  }

  convertTimeIn12HrFormatFromDate(date: any) {
    return moment(date).format('hh:mm:ss A')
  }

  convertTimeIn24HrFormatFromDate(date: any) {
    return moment(date).format('HH:mm:ss')
  }

  noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  emailPattern() {
    return /^([a-zA-Z][\w+-]+(?:\.\w+)?)@([\w-]+(?:\.[a-z]{2,10})+)$/;
    // return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    // const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/; // .test(control.value);
    // return pattern; //  ? { 'emailId': { value: control.value } } : true;
  }

  phoneNumberPattern() {
    const pattern = /^\d{10}$/; // .test(control.value);
    return pattern; //  ? { 'phoneval': { value: control.value } } : true;
  }

  onlyAlphabetsAllowed() {
    const pattern = /^[a-zA-Z]+$/;
    return pattern;
  }

  onlyAlphabetsNumbersAndSpacesNotAllowed() {
    const pattern = /^(?![0-9\s]*$)[a-zA-Z0-9\s]+$/;
    return pattern;
  }
  
 

  nonNegativeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value < 0) {
        return { nonNegative: true };
      }
      return null;
    }
  }


  maxTwoDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value > 99) {
        return { maxTwoDigits: true };
      }
      return null;
    };
  }
}
