import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from "primeng/api";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = new Date();
  title = "angular-idle-timeout";
  constructor(
    private primengConfig: PrimeNGConfig,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== "" && event.url !== "/login" && event.url !== "/") {
          this.watchTimer(this.idle, this.keepalive);
        }
      }
    });
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    // this.confirm1();
  }

  watchTimer(idle: any, keepalive: any) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(1800); // 30 mins
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(300);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
    });

    idle.onTimeoutWarning.subscribe((countdown: any) => {
      this.idleState = "You will be logged out in " + countdown + " seconds!";
      this.confirm(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }

  stay() {
    this.reset();
  }

  public logout() {
    this.idle.stop();
    this.confirmationService.close();
    this.router.navigate([""]);
  }

  confirm(state: string) {
    this.confirmationService.confirm({
      header: "You have been idle...",
      message: state,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.reset();
        this.messageService.add({
          severity: "info",
          summary: "Stay Back",
          detail: "Thank you for staying back...!",
        });
      },
      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.logout();
            this.messageService.add({
              severity: "error",
              summary: "Logout",
              detail: "You have Logged out. Please Login again...!",
            });
            break;
          case ConfirmEventType.CANCEL:
            this.reset();
            this.messageService.add({
              severity: "warn",
              summary: "Cancelled",
              detail: "You have cancelled",
            });
            break;
        }
      },
    });
  }

  confirm1() {
    this.confirmationService.confirm({
      message: "No Venues assigned to you. Please contact admin.",
      icon: "pi pi-exclamation-triangle",
      accept: () => { },
      reject: () => { },
    });
  }
}
