import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from "@angular/core";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppLayoutModule } from "./layout/app.layout.module";
import { CoreModule } from "./modules/core/core.module";
import { SharedModule } from "./modules/shared/shared.module";
import { ConfirmationService, MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./modules/core/interceptors/http.interceptor";
import { StorageHelperService } from "./modules/core/services/storage-helper.service";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { JwtModule } from "@auth0/angular-jwt";

export function tokenGetter() {
  return localStorage.getItem("accessToken");
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AppLayoutModule,
    CoreModule,
    SharedModule,
    ToastModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    JwtModule,
    NgIdleKeepaliveModule.forRoot(),
    /* JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["example.com"],
        disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }), */
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
      deps: [StorageHelperService],
    },
    MessageService,
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
