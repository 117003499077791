import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LayoutService } from "src/app/layout/service/app.layout.service";
import { AppSidebarComponent } from "./app.sidebar.component";
import { StorageHelperService } from "../modules/core/services/storage-helper.service";
import { SharedService } from "../modules/shared/services/shared.service";
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from "primeng/api";
import { Router } from "@angular/router";

@Component({
  selector: "app-topbar",
  templateUrl: "./app.topbar.component.html",
})
export class AppTopbarComponent implements OnInit {
  venues: any[] = [];
  selectedVenue!: any;
  @ViewChild("menubutton") menuButton!: ElementRef;
  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
  activeItem!: number;
  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
    private storageService: StorageHelperService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.storageService.getItemFromLocal("venues")) {
      this.venues = this.storageService.getItemFromLocal("venues");
    } else {
      this.sharedService.venueListBehaviorSubject.subscribe({
        next: (venues: any) => {
          this.venues = venues;
        },
      });
    }

    if (this.venues) {
      this.selectedVenue = this.venues[0];
      this.sharedService.setVenueBehaviorSubject(this.selectedVenue);
      this.onVenueChange(false);
    }
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onSidebarButtonClick() {
    this.layoutService.showSidebar();
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  onVenueChange(isChanged?: boolean) {
    if (isChanged) {
      this.confirmVenue(
        "You are switching the Venue. Do you want to switch the Venue?",
        "Switch the Venue"
      );
    }
  }

  confirmVenue(state: string, headerText: string) {
    let previousSelectedVenue = "";
    this.sharedService.getVenueBehaviorSubject().subscribe({
      next: (venue: any) => {
        previousSelectedVenue = venue;
      },
    });
    this.confirmationService.confirm({
      header: headerText,
      message: state,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        // this.reset();
        this.messageService.add({
          severity: "info",
          summary: "Yes",
          detail: "You chose " + this.selectedVenue.name,
        });
        this.sharedService.setVenueBehaviorSubject(this.selectedVenue);
      },
      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            // this.logout();
            this.messageService.add({
              severity: "info",
              summary: "No",
              detail: "You have choosen to stay back in the current Venue!",
            });
            this.selectedVenue = previousSelectedVenue;
            break;
        }
      },
    });
  }
  redirectToProfile() {
    this.router.navigate(['/home/profile']);
}
}
