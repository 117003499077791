<div class="surface-0">
  <div
    class="flex align-items-center justify-content-between flex-column h-screen"
  >
    <div
      class="flex flex-column align-items-center justify-content-center w-full md:w-4 h-full text-center py-6 px-4"
    >
      <a [routerLink]="['/']" class="mb-6">
        <img
          src="./../../../assets/Fitzone_Logo.svg"
          alt="Fitzone Logo"
          style="width: 100%"
        />
        <!-- <svg
          height="56"
          viewBox="0 0 17 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H6.00019V3.82345L17 1.66667V6.66667L6.00019 8.82345V10.4901L17 8.33333V13.3333L6.00019 15.4901V20H0V0Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="3.33335"
              y1="3.08442e-08"
              x2="8.49995"
              y2="20"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="var(--primary-400)" />
              <stop offset="1" stop-color="var(--primary-700)" />
            </linearGradient>
          </defs>
        </svg> -->
      </a>
      <div class="flex flex-column">
        <!-- email -->
        <ng-container *ngIf="!isOtpSent" [formGroup]="emailForm">
          <span class="p-input-icon-left w-full mb-4">
            <i class="pi pi-envelope"></i>
            <input
              id="email"
              type="text"
              pInputText
              formControlName="email"
              class="w-full md:w-25rem text-color-secondary surface-50 border-200"
              placeholder="Email"
            />
            <div
            class="error"
            *ngIf="emailForm.controls['email'].value && emailForm.controls['email'].errors?.['notValidEmail']"
          >
          Please enter a valid email.
          </div>
          </span>
          <button
            pButton
            pRipple
            label="Continue"
            class="w-full mb-4"
            [disabled]="emailForm.invalid"
            (click)="sendOtp()"
          ></button>
        </ng-container>

        <!-- OTP -->
        <ng-container *ngIf="isOtpSent">
          <h3>Enter Verification Code</h3>
          <p>
            We have sent you a 4 digit OTP to
            <span style="font-weight: bold; overflow-wrap: anywhere;">{{ emailForm?.value?.email }}&nbsp;&nbsp;</span>
            <a [routerLink]="'/'" (click)="navigatetoEmail(emailForm?.value?.email)"
              >Edit</a
            >
          </p>
          <span class="container inputfield">
            <input
              id="otp1"
              type="text"
              pInputText
              [formControl]="otp1"
              class="otp-input"
              maxlength="1"
                            min="0"
                            max="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            onkeydown="
    if (/^[eE.\+\-]$/.test(event.key))
      event.preventDefault();" 
      onPaste="  if (!/^\d+$/.test(event.clipboardData.getData('text/plain')))event.preventDefault();"
              (keyup)="keyUp1($event)"
            />
            <input
              id="otp2"
              type="text"
              pInputText
              [formControl]="otp2"
              class="otp-input"
              maxlength="1"
              min="0"
              max="9"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              onkeydown="
if (/^[eE.\+\-]$/.test(event.key))
event.preventDefault();" 
onPaste="  if (!/^\d+$/.test(event.clipboardData.getData('text/plain')))event.preventDefault();"
              (keyup)="keyUp2($event)"
            />
            <input
              id="otp3"
              type="text"
              pInputText
              [formControl]="otp3"
              class="otp-input"
              maxlength="1"
                            min="0"
                            max="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            onkeydown="
    if (/^[eE.\+\-]$/.test(event.key))
      event.preventDefault();" 
      onPaste="  if (!/^\d+$/.test(event.clipboardData.getData('text/plain')))event.preventDefault();"
              (keyup)="keyUp3($event)"
            />
            <input
              id="otp4"
              type="text"
              pInputText
              [formControl]="otp4"
              class="otp-input"
              maxlength="1"
                            min="0"
                            max="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            onkeydown="
    if (/^[eE.\+\-]$/.test(event.key))
      event.preventDefault();" 
      onPaste="  if (!/^\d+$/.test(event.clipboardData.getData('text/plain')))event.preventDefault();"
              (keyup)="keyUp4($event)"
            />
          </span>
          <div class="error" *ngIf="wrongOtpCount > 0 && wrongOtpCount < 2">
            Please enter correct OTP
        </div>
          <countdown
            *ngIf="!isResentOTP && resendOtpCount < 2 && wrongOtpCount < 2"
            #cd
            [config]="{ leftTime: 30, format: 'm:s' }"
            (event)="handleTimerEvent($event)"
          ></countdown>
          <!-- <p-label>resend</p-label> -->
          <!-- <p-chip
            label="Resend OTP"
            *ngIf="isResentOTP"
            class="resend-otp"
            (click)="sendOtp()"
          ></p-chip -->
          <div class="otp-resend">
            <a
              class="resend-otp"
              *ngIf="isResentOTP && wrongOtpCount < 2"
              (click)="otpCount();sendOtp()"
              >Resend OTP</a
            >
          </div>
          <br />
          <button
            pButton
            pRipple
            label="Login"
            class="w-full mb-4"
            [disabled]="
              otp1.invalid || otp2.invalid || otp3.invalid || otp4.invalid
            "
            (click)="submitOtp()"
          ></button>
        </ng-container>
      </div>
    </div>
    <div class="flex flex-wrap align-items-center pb-8 px-4">
      <h6 class="m-0 font-medium text-300" style="line-height: 17px">
        Copyright Ⓒ Fitzone Technologies Private Limited
      </h6>
    </div>
  </div>
</div>

<app-config [minimal]="true"></app-config>
