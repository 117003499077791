<router-outlet></router-outlet>
<app-spinner></app-spinner>
<p-toast position="bottom-right"></p-toast>
<!-- <p-confirmPopup [rejectVisible]="true" key="no-venues"></p-confirmPopup> -->
<p-confirmDialog #cd [style]="{ width: '50vw' }" [closeOnEscape]="false">
  <!-- <ng-template pTemplate="header">
    <h3>You have been idle...</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      styleClass="p-button-danger"
      type="button"
      icon="pi pi-times"
      label="Logout"
      (click)="cd.reject()"
    ></p-button>
    <p-button
      type="button"
      icon="pi pi-check"
      label="Stay"
      (click)="cd.accept()"
    ></p-button>
  </ng-template> -->
</p-confirmDialog>

<p-confirmDialog [rejectVisible]="true" key="no-venues"></p-confirmDialog>
