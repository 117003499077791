import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppToastService } from "./app-toast.service";
import { LoaderService } from "./loader.service";
import { environment } from "src/environments/environment";
import { APIDataModel } from "../constants/app.apiEndPoints";
import { Observable, catchError, finalize, tap } from "rxjs";
import { ERRORCODE_MAP } from "../constants/app.error.messages";

@Injectable({
  providedIn: "root",
})
export class MasterService {
  constructor(
    private toasterService: AppToastService,
    private loaderService: LoaderService,
    private http: HttpClient
  ) { }

  get<T>(
    url: string,
    options?: {
      body?: any;
      headers?:
      | HttpHeaders
      | {
        [header: string]: string | string[];
      };
      params?:
      | HttpParams
      | {
        [param: string]: string | string[] | number;
      };

      observe?: any;
      responseType?: "arraybuffer" | "blob" | "json" | "text";
      withCredentials?: boolean;
    },
    customOptions?: CustomHttpServiceOption
  ): Observable<T> {
    return this.request<T>("GET", url, options, customOptions);
  }

  post<T>(
    url: string,
    body?: any,
    options?: {
      body?: any;
      headers?:
      | HttpHeaders
      | {
        [header: string]: string | string[];
      };
      params?:
      | HttpParams
      | {
        [param: string]: string | string[] | number;
      };

      observe?: any;
      responseType?: "arraybuffer" | "blob" | "json" | "text";
      withCredentials?: boolean;
    },
    customOptions?: CustomHttpServiceOption
  ): Observable<T> {
    return this.request<T>(
      "POST",
      url,
      { body: body, ...options },
      customOptions
    );
  }

  delete<T>(
    url: string,
    body?: any,
    options?: {
      body?: any;
      headers?:
      | HttpHeaders
      | {
        [header: string]: string | string[];
      };
      params?:
      | HttpParams
      | {
        [param: string]: string | string[] | number;
      };

      observe?: any;
      responseType?: "arraybuffer" | "blob" | "json" | "text";
      withCredentials?: boolean;
    },
    customOptions?: CustomHttpServiceOption
  ): Observable<T> {
    return this.request<T>("DELETE", url, options, customOptions);
  }

  put<T>(
    url: string,
    body?: any,
    options?: {
      body?: any;
      headers?:
      | HttpHeaders
      | {
        [header: string]: string | string[];
      };
      params?:
      | HttpParams
      | {
        [param: string]: string | string[] | number;
      };

      observe?: any;
      responseType?: "arraybuffer" | "blob" | "json" | "text";
      withCredentials?: boolean;
    },
    customOptions?: CustomHttpServiceOption
  ): Observable<T> {
    return this.request<T>(
      "PUT",
      url,
      { body: body, ...options },
      customOptions
    );
  }

  request<T>(
    method: string,
    url: string,
    options?: {
      body?: any;
      headers?:
      | HttpHeaders
      | {
        [header: string]: string | string[];
      };
      params?:
      | HttpParams
      | {
        [param: string]: string | string[] | number;
      };
      observe?: any;
      responseType?: "arraybuffer" | "blob" | "json" | "text";
      withCredentials?: boolean;
    },
    customOptions?: CustomHttpServiceOption
  ): Observable<T> {
    return this.sendRequest<T>(
      () => this.http.request(method, url, options),
      customOptions
    );
  }

  private sendRequest<T>(
    callback: () => Observable<T>,
    customOptions: CustomHttpServiceOption = {}
  ): Observable<T> {
    if (customOptions.initMessage) {
      this.toasterService.showSuccess(customOptions.initMessage);
    }

    if (customOptions.loader) {
      this.loaderService.startLoader();
    }

    return callback().pipe(
      finalize(() => {
        if (customOptions.loader) {
          this.loaderService.stopLoader();
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toasterService.clearAllToasterMessages();
        if (!customOptions.supressHttpError || customOptions.errorMessage) {
          this.toasterService.showError((err.error.statusmessage ?? err.error.statusMessage) ?? customOptions.errorMessage);
        }
        // if (customOptions.errorMessage) {
        //   this.toasterService.showError(customOptions.errorMessage);
        // }
        throw err;
      }),
      tap((data: any) => {
        if (customOptions.successMessage) {
          this.toasterService.showSuccess(customOptions.successMessage);
        }

        if (!customOptions.suppressErrorCodes) {
          if (data && data.hasOwnProperty("ErrorCodes")) {
            const errCodes: any[] = data.ErrorCodes;
            if (errCodes.length) {
              errCodes.forEach((erCode) => {
                const errMsg = ERRORCODE_MAP.get(+erCode);
                if (errMsg) {
                  this.toasterService.showError(errMsg);
                }
              });
            }
          }
        }
      })
    );
  }

  constructApiUrl(apiData: APIDataModel) {
    if (apiData.url) {
      return apiData.url;
    }
    const type = apiData.type;
    const apiEndPoint = apiData.apiEndPoint;
    const host = apiData.host || environment.host;
    const version = apiData.version || environment.apiVersion;

    const newHost = host.replace("{apiVersion}", version);
    const newUrl = newHost + apiEndPoint;
    return newUrl;
  }
}

export interface APIModel {
  path: string;
  host?: string;
  version?: string;
}

export interface CustomHttpServiceOption {
  initMessage?: string;
  successMessage?: string;
  errorMessage?: string;
  loader?: boolean;
  suppressErrorCodes?: boolean;
  supressHttpError?: boolean;
}
